.todoItem__wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  height: 62px;
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
}

.todoItem__wrapper h3 {
  color: rgba(0, 0, 0, 0.56);
}

.todoItem__wrapper:last-child {
  border: none;
}
.input__itemTodo {
  width: 0;
  height: 0;
  opacity: 0;
}

.label__todo {
  display: flex;
  align-items: center;
  color: #9e9e9e;
  position: relative;
}

.label__todo > span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  border: 2px solid #9e9e9e;
  margin-right: 15px;
  border-radius: 5px;
  transition: all 0.3s;
}

input:checked + .label__todo > span {
  border: 10px solid var(--bg-color);
  animation: bounce 250ms;
}

input:checked + .label__todo > span::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 5px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);

  transform-origin: 0% 100%;
  animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    background-color: var(--bg-color);
    border-color: #fff;
    transform: translate(0, 0) rotate(45deg);
  }
  33% {
    width: 5px;
    height: 0;
    background-color: var(--bg-color);
    border-color: #fff;
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    width: 5px;
    height: 9px;
    border-color: #fff;
    background-color: var(--bg-color);

    transform: translate(0, -9px) rotate(45deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.text {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.text::after {
  position: absolute;
  top: calc(50% - 0.05em);
  left: 0;
  width: 100%;
  content: "";
  display: block;
  border-top: 0.1em solid currentColor;
  transform: translateX(-100%);
  transition: transform 0.25s ease-in-out;
}

.text.strikethrough::after {
  transform: translateX(0);
}
