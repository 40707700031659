.todo h1 {
  margin-bottom: 34px;
}

.todo__wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.todo {
  flex-grow: 1;
  padding: 4rem 4rem 0 5.5rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  max-width: var(--todo-width);
}
