:root {
  --nav-width: 92px;
  --todo-width: 30vw;
  --primary-color: #3f52bb;
  --primary-color-opacity: rgba(63, 82, 187, 0.7);
  --bg-color: #4f63d7;
  --sub-color: #b6cefc;
  --white-color: #ffff;

  --body-font: "Roboto", sans-serif;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --z-fixed: 100;
  --adjust-height: 20%;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

#body {
  padding-left: var(--nav-width);
}

h1 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--nav-width);
  padding: 1.5rem 1.5rem 2rem;
  background-color: var(--bg-color);
  color: var(--white-color);
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_brand {
  display: inline-flex;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
}

.nav_toggle {
  font-size: 1.6rem;
  padding: 0.6rem;
  cursor: pointer;
}

.nav_logo {
  color: var(--white-color);
  font-weight: 600;
}

.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.75rem;
  padding: 0.75rem;
  color: var(--white-color);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: 0.3s;
  cursor: pointer;
}

.nav_link:hover {
  background-color: var(--primary-color-opacity);
}

.nav_icon {
  font-size: 1.25rem;
}

.nav_name {
  font-size: var(--small-font-size);
}

.expander {
  width: calc(var(--nav-width) + 9.25rem);
}

.body-pd {
  padding: 2rem 0 0 16rem;
}

.active {
  background-color: var(--primary-color);
}
