.pomodoro {
  flex-grow: 4;
  background-color: #f1f1f1;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  padding: 4rem 4rem 0 5.5rem;
  max-width: calc(100vw - 30vw - var(--nav-width));
}

.pomodoro > h1 {
  margin-bottom: 34px;
}

.top {
  display: flex;
  /* justify-content: ce; */
  height: 300px;
  align-items: center;
  gap: 50px;
}

.pomo__control {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
}
.pomo__control > .header_wrapper {
  height: 40px;
  position: relative;
  width: 60%;
  border-left: 5px solid var(--bg-color);
}
.pomo__control > .header_wrapper > .header {
  margin: 0;
  font-size: 25px;
  position: absolute;
  top: 50%;
  color: var(--primary-color);
  transform: translate(0, -50%);
  left: 20px;
}

.selector_wrapper {
  width: 60%;
  position: relative;
  border-radius: 5px;
  background-color: #e8e8e8;
  margin-top: 10px;
}
.selector_wrapper > .selector {
  padding: 7px 10px 7px 10px;
  background-color: #e8e8e8;
  width: 95%;
  border-radius: 5px;
  border: none;
  color: rgba(0, 0, 0, 0.56);
}

.selector_wrapper > .selector:focus {
  outline: none;
}

.btn {
  display: block;
  width: 170px;
  height: 50px;
  border-radius: 30px;
  border: 0;
  background-color: var(--bg-color);
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}
.btn:hover {
  opacity: 0.9;
}
.pomo__control_mid {
  width: 100%;
}

.pomo__control_mid > label {
  padding-left: 7px;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 14px;
}
.bottom {
  display: flex;
  height: 300px;
  width: 100%;
  /* justify-content: ce; */
  gap: 50px;
  align-items: center;
}
